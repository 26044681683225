* {
  margin: 0;
  padding: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

*::-webkit-scrollbar {
  display: none;
}

:root {
  font-family: "Onest", "Roboto", "Segoe UI", sans-serif;
}

@font-face {
  font-family: 'Onest';
  src: local('Onest-Regular'), url(./Fonts/Onest-Regular.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Onest';
  src: local('Onest-Medium'), url(./Fonts/Onest-Medium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Onest';
  src: local('Onest-Bold'), url(./Fonts/Onest-Bold.ttf) format('truetype');
  font-weight: bold;
}