.swipe-right {
    animation: wobble_right .5s 1;
}

.swipe-left {
    animation: wobble_left .5s 1;
}

a {
    color: #e9dfef;
    padding: 2px 4px;
}


@keyframes wobble_left {
    100% {
        transform: translate(-100px, 0) rotate(-25deg);
    }
}

@keyframes wobble_right {
    100% {
        transform: translate(100px, 0) rotate(25deg);
    }
}